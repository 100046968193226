import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const iconLogo = {
  height: "20px",
  width: "20px",
  marginRight: "10px",
  transform: "translateY(5px)",
  objectFit: "contain",
};
const Mail = {
  margin: "0px 0px 0px",
  fontSize: "12px",
  lineHeight: "1.38",
  textDecoration: "none",
};
const MailLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  fontSize: "12px",
  margin: "0px 0px 0px",
};

export default function PostalAdress(adress) {
  return (
    <p style={Mail}>
      <img src={`https://files.myclientisrich.com/${client}/map.png`} style={iconLogo} alt="logo"></img>
      <a href={`https://www.google.fr/maps/place/11+Parv.+de+Rotterdam,+59777+Lille/@50.6379287,3.0767141,18.96z`} target="_blank" style={MailLink}>
        {adress.adress}
      </a>
    </p>
  );
}
