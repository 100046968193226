import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const MailLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  fontSize: "12px",
  margin: "0px 0px 0px",
};
const iconLogo = {
  display: "block",
  marginRight: "10px",
  transform: "translateY(5px)",
  objectFit: "contain",
};

export default function Email(email) {
  return (
    <table>
      <tr>
        <td>
          <img src={`https://files.myclientisrich.com/${client}/mail.png`} style={iconLogo} alt="logo" height={15} width={20}></img>
        </td>
        <td>
          <a href={`mailto:${email.email}`} style={{ ...MailLink, textDecoration: "none" }}>
            {email.email}
          </a>
        </td>
      </tr>
    </table >
  );
}
