import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { shape, string } from "prop-types";
import Email from "./items/email";
import Phone from "./items/phone";
import PostalAdress from "./items/postalAdress";
import Instagram from "./items/instagram";
import Linkedin from "./items/linkedin";

const config = require("../../config.json")
const client = config.APP_CLIENT;
const imgName = config.APP_IMG_NAME;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;
const siteInternet = config.APP_SITEINTERNET;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContainerSocial = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "0px",
};
const SignatureStyle = {
  position: "relative",
  borderSpacing: "10px",
  backgroundColor: "black",
  width: "400px",
  height: "230px",
};
const ImageWrapper = {
  position: "relative",
  width: "130px",
  objectFit: "cover",
};
const imgLeft = {
  width: "150px",
  top: "0",
};
const imgLeftCenter = {
  position: "absolute",
  height: "130px",
  width: "130px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const Image = {
  width: "130px",
  height: "100%",
  margin: "auto",
  display: "block",
};
const ImageObject = {
  width: "130px",
  height: "100%",
  margin: "auto",
  display: "block",
  objectFit: "cover",
};
const TextWrapper = {
  position: "static",
  fontFamily: "Arial",
  lineHeight: "1.45",
  marginBottom: "0px !important",
};
const Name = {
  fontSize: "15px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "1",
};
const Job = {
  margin: "3px 0px 5px 0px",
  letterSpacing: "1px",
  color: compagnyColorPrimary,
  fontWeight: "400",
  fontSize: "13px",
};
const TextUnderImg = {
  color: compagnyColorPrimary,
  textTransform: "uppercase",
  textAlign: "center",
  fontFamily: "Arial",
  fontWeight: "bold",
  fontSize: "9px",
  lineHeight: "11px",
}
const leftSide = {
  position: "static",
  width: "30%",
}
const separator = {
  width: "75px",
  height: "1px",
  backgroundColor: compagnyColorPrimary,
  margin: "10px 0px",
}

function constructImgUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${imgName}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, file, typeImage }) => {
  let urlUploadsImg = "";
  if (user.urlImage === "" || user.urlImage === null) {
    if (typeImage === "static") {
      urlUploadsImg = constructImgUrl(client, 'jpg');
    } else if (typeImage === "gif") {
      urlUploadsImg = constructImgUrl(client, 'gif');
    }
  }

  let positionImage = false;
  let positionLeft;
  if (message === true) {
    positionImage = ImageObject;
    positionLeft = imgLeftCenter
  } else {
    positionImage = Image;
    positionLeft = imgLeft
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Slash Consulting - Signature
        </title>
      </Helmet>
      <div className='wrapper-table'>
        <table className="signature" style={SignatureStyle}>
          <tbody>
            <tr>
              <td style={leftSide}>
                <div className="image__wrapper" style={ImageWrapper}>
                  {user.urlImage ? (
                    <a style={positionLeft} href={`${siteInternet}`} target='_blank' rel="noreferrer">
                      <img src={urlUploadsImg} style={positionImage} alt="logo" />
                    </a>
                  ) : (
                    <a style={positionLeft} href={`${siteInternet}`} target='_blank' rel="noreferrer">
                      <img src={urlUploadsImg} style={positionImage} alt="logo" />
                    </a>
                  )}
                </div>
                {user.slogan ? (
                  <div style={TextUnderImg}
                    dangerouslySetInnerHTML={{ __html: user.slogan }}>
                  </div>
                ) : null}
              </td>
              <td className="text__wrapper" style={TextWrapper}>
                <p style={Object.assign({}, Name, { color: compagnyColorPrimary })}>
                  {user.firstname}
                  &nbsp;
                  {user.lastname}
                </p>
                <h4 style={Job}>{user.job}</h4>
                {user.phone ? (
                  <Phone phone={user.phone} />
                ) : null}
                {user.postalAdress ? (
                  <PostalAdress adress={user.postalAdress} />
                ) : null}
                {user.email ? (
                  <Email email={user.email} />
                ) : null}
                <div style={separator}></div>
                <p style={ContainerSocial}>
                  {user.linkedin ? (
                    <Linkedin linkedin={user.linkedin} />
                  ) : null}
                  {user.instagram ? (
                    <Instagram instagram={user.instagram} />
                  ) : null}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper >
  );
};

SignatureFormatter.propTypes = {
  user: shape({
    name: string.isRequired,
    job: string.isRequired,
    availability: string,
    phone: string
  }).isRequired
};

export default SignatureFormatter;
